* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #f4f6f8;
  height: 100%;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-left: 1px solid #ededed;
}
::-webkit-scrollbar {
  width: 7px;
  background-color: #c1c1c1;
}
::-webkit-scrollbar-thumb {
  background: #c1c1c1;
}
